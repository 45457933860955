import React, { useEffect, useState, useRef, useCallback } from "react";
import { UserContext } from "../../App";

import SearchBar from "../../components/search-bar/search-bar.component";
import FeaturedCollection from "../../components/featured-collection/featured-collection.component";
import ProductCollection from "../../components/product-collection/product-collection.component";

export const SearchPage = () => {
  const [categoryButtonsVisible, setCategoryButtonsVisible] = useState(true);
  const featuredCategories = useRef();
  const productsSection = useRef();
  const [categoryFilter, setCategoryFilter] = useState("");
  const [subcategoryFilter, setSubcategoryFilter] = useState("");
  const [productsFiltered, setProductsFiltered] = useState(false);
  const [rebindAllProducts, setRebindAllProducts] = useState(false);

  const updateCategoryFilter = useCallback(
    (val) => {
      setCategoryFilter(val);
    },
    [setCategoryFilter]
  );

  const updateSubcategoryFilter = useCallback(
    (val) => {
      setSubcategoryFilter(val);
    },
    [setSubcategoryFilter]
  );

  const updateCategorButtonsVisible = useCallback(
    (val) => {
      setCategoryButtonsVisible(val);
    },
    [setCategoryButtonsVisible]
  );

  const updateProductsFiltered = useCallback(
    (val) => {
      setProductsFiltered(val);
    },
    [setProductsFiltered]
  );

  const updateRebindAllProducts = useCallback(
    (val) => {
      setRebindAllProducts(val);
    },
    [setRebindAllProducts]
  );

  const scrollPastCategories = () => {
    //productsSection.current.scrollIntoView();
    //window.scrollTo(0, productsSection.current.offsetTop);

    const yOffset = -80;
    const y =
      productsSection.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;

    window.scrollTo({ top: y, behavior: "smooth" });
  };

  useEffect(() => {
    // console.log("filters");
    // console.log(categoryFilter);
    // console.log(subcategoryFilter);

    const onScroll = (e) => {
      const top = featuredCategories.current.getBoundingClientRect().top;
      //console.log(featuredCategories.current.getBoundingClientRect());
      //here we check if element top reference is on the top of viewport
      /*
       * If the value is positive then top of element is below the top of viewport
       * If the value is zero then top of element is on the top of viewport
       * If the value is negative then top of element is above the top of viewport
       * */
      if (top <= 0) {
        setCategoryButtonsVisible(false);
        //console.log("Element is outside view");
      } else {
        setCategoryButtonsVisible(true);
        //console.log("Element is in view or above the viewport");
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [
    categoryFilter,
    subcategoryFilter,
    categoryButtonsVisible,
    rebindAllProducts,
  ]);

  return (
    <div>
      <div
        className="sticky-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 ms-3">
            <h4 className="text-uppercase">Search Products</h4>
          </div>
        </div>
        <div className="p-3 pt-0">
          <SearchBar
            displayCategoryDdl={
              !categoryButtonsVisible || categoryFilter !== ""
            }
            // filters={filters}
            // setFilters={setFilters()}
            categoryFilter={categoryFilter}
            subcategoryFilter={subcategoryFilter}
            updateCategoryFilter={updateCategoryFilter}
            updateSubcategoryFilter={updateSubcategoryFilter}
            updateProductsFiltered={updateProductsFiltered}
            //updateCategoriesVisible={updateCategoriesVisible}
            updateRebindAllProducts={updateRebindAllProducts}
          />
        </div>
      </div>
      <br></br>
      <div ref={featuredCategories}>
        <UserContext.Consumer>
          {(value) => (
            <FeaturedCollection
              userCtx={value}
              updateCategoryFilter={updateCategoryFilter}
              updateCategorButtonsVisible={updateCategorButtonsVisible}
              scrollPastCategories={scrollPastCategories}
              updateProductsFiltered={updateProductsFiltered}
              updateRebindAllProducts={updateRebindAllProducts}
            />
          )}
        </UserContext.Consumer>
      </div>
      <hr style={{ margin: 20, marginTop: 40, marginBottom: 25 }} />
      <div ref={productsSection}>
        <UserContext.Consumer>
          {(value) => (
            <ProductCollection
              productsUrl={"/products/"}
              userCtx={value}
              categoryFilter={categoryFilter}
              subcategoryFilter={subcategoryFilter}
              productsFiltered={productsFiltered}
              updateProductsFiltered={updateProductsFiltered}
              rebindAllProducts={rebindAllProducts}
              updateRebindAllProducts={updateRebindAllProducts}
            />
          )}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
