import React from "react";
//import ProductCard from "../product-card/product-card.component";
// import SearchBar from "../search-bar/search-bar.component";
import { CategoryWrapper } from "./featured-collection.styles";

import womensIcon from "../../assets/girl-dress.png";
import menIcon from "../../assets/mens-shirts-half-sleeve.png";
import unisexIcon from "../../assets/tshirt-4227_free.png";
import accessoriesIcon from "../../assets/eyeglasses-1832_free.png";

const FeaturedCollection = (props) => {
  const { userCtx } = props;
  const { updateCategorButtonsVisible } = props;
  const { updateCategoryFilter } = props;
  const { scrollPastCategories } = props;
  const { updateProductsFiltered } = props;
  const { updateRebindAllProducts } = props;

  const handleCategoryClick = (categoryName) => {
    updateRebindAllProducts(true);
    updateCategorButtonsVisible(false);
    updateCategoryFilter(categoryName);
    scrollPastCategories();
    updateProductsFiltered(false);
  };

  return (
    <div className="grey-bg container-fluid">
      <div className="container">
        <CategoryWrapper>
          <div className="row">
            <button
              type="button"
              className="col btn btn-default btn-lg"
              onClick={(event) => {
                event.preventDefault();
                handleCategoryClick("Women's Clothing");
              }}
            >
              <span className="btn-label">
                <img className="icon" src={womensIcon} alt="Women's clothing" />
              </span>{" "}
              Women's Clothing
            </button>

            <button
              type="button"
              className="col btn btn-default btn-lg"
              onClick={(event) => {
                event.preventDefault();
                handleCategoryClick("Men's Clothing");
              }}
            >
              <span className="btn-label">
                <img className="icon" src={menIcon} alt="Men's clothing" />
              </span>{" "}
              Men's Clothing
            </button>
            <button
              type="button"
              className="col btn btn-default btn-lg"
              onClick={(event) => {
                event.preventDefault();
                handleCategoryClick("Unisex Clothing");
              }}
            >
              <span className="btn-label">
                <img className="icon" src={unisexIcon} alt="Unisex clothing" />
              </span>{" "}
              Unisex Clothing
            </button>
          </div>
          <div className="row">
            <button
              type="button"
              className="col btn btn-default btn-lg"
              onClick={(event) => {
                event.preventDefault();
                handleCategoryClick("Fashion Accessories");
              }}
            >
              <span className="btn-label">
                <img
                  className="icon"
                  src={accessoriesIcon}
                  alt="Fashion Accessories"
                />
              </span>{" "}
              Fashion Accessories
            </button>
          </div>
        </CategoryWrapper>
      </div>
    </div>
  );
};

export default FeaturedCollection;
