import styled from "styled-components";

export const SearchTextBox = styled.div`
  width: 100%;

  .search {
    position: relative;
    /* box-shadow: 0 0 40px rgba(51, 51, 51, 0.1); */
    width: 100%;
  }

  .search input {
    /* height: 60px; */
    text-indent: 25px;
    /* border: 2px solid #d6d4d4; */
    /* display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    */
  }

  .search input:focus {
    box-shadow: none;
    /* border: 2px solid #00c3d2; */
    border-color: #00c3d2;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(0, 195, 210, 0.25);
  }

  .search .fa-search {
    position: absolute;
    top: 10px;
    left: 13px;
  }
`;
