import React from "react";
// import { UserContext } from "../../App";

// import FeaturedCollection from "../../components/featured-collection/featured-collection.component";
// import ProductCollection from "../../components/product-collection/product-collection.component";

export const HomePage = () => {
  return (
    <div className="container" style={{maxWidth: "1112px"}}>
      <section id="minimal-statistics">
        <div className="row">
          <div className="col-12 mt-3 mb-1">
            <h4 className="text-uppercase">Home</h4>
            <p>Supporting Dropshippers with Better Products.</p>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-save"
                        style={{ fontSize: "3rem", color: "#80bfff" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>278</h3>
                      <span>Total Items Imported</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-truck"
                        style={{ fontSize: "3rem", color: "#ffcccc" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>156</h3>
                      <span>Orders This Month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-sliders2-vertical"
                        style={{ fontSize: "3rem", color: "#adebad" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>Free</h3>
                      <span>Current Plan</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container d-none" style={{maxWidth: "1112px"}}>
        <div className="row mt-4 d-flex flex-wrap">
          <div className="col-3 d-flex flex-column justify-content-start d-none">
            <div className="card shadow">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-save"
                        style={{ fontSize: "3rem", color: "#68aaed" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>278</h3>
                      <span>Total Items Imported</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2 shadow">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-truck"
                        style={{ fontSize: "3rem", color: "#ffadad" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>156</h3>
                      <span>Orders This Month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4 shadow d-none">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-currency-exchange"
                        style={{ fontSize: "3rem", color: "#49c649" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>$2,917.21 CAD</h3>
                      <span>Profit</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-2 shadow">
              <div className="card-content">
                <div className="card-body">
                  <div className="media d-flex justify-content-between">
                    <div className="align-self-center">
                      <i
                        className="bi bi-sliders2-vertical"
                        style={{ fontSize: "3rem", color: "#efb52d" }}
                      ></i>
                    </div>
                    <div className="media-body text-end">
                      <h3>Free</h3>
                      <span>Current Plan</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card mt-4 shadow h-100 d-none">
              <div className="card-content">
                <div className="card-body">
                  <i className="bi bi-caret-right-square"></i> Jemmity
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="card shadow">
              <div className="card-body">
                <h5 className="card-title">A Quick Overview</h5>
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 0,
                        paddingBottom: "56.25%",
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        //width="100%"
                        //height="auto"
                        src="https://www.youtube.com/embed/JsbnRkRwCeI?rel=0"
                        title="Welcome to Jemmity"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="row mt-4">
          <div className="col">
            <div className="card shadow">
              <div className="card-body">
                <h5 className="card-title">A Quick Overview</h5>
                <div className="row">
                  <div className="col-9 d-flex justify-content-center">
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: 0,
                        paddingBottom: "56.25%",
                        overflow: "hidden",
                      }}
                    >
                      <iframe
                        //width="100%"
                        //height="auto"
                        src="https://www.youtube.com/embed/JsbnRkRwCeI?rel=0"
                        title="Welcome to Jemmity"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                  </div>
                  <div className="col-3 border-start">
                    <ul className="list-group">
                      <li className="list-group-item active border-top-0 border-start-0 border-end-0 rounded-0">
                        <i className="bi bi-caret-right-square"></i> Jemmity
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
