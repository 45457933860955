import React, { useState, useEffect } from "react";
import { ImportItem } from "../import-item/import-item.component";

//import httpCommon from "../../http-common";

import ImportList from "../../data/import_list.json";
import ShopCollections from "../../data/shop_collections.json";

export const ImportCollection = (props) => {
  const { userInfo, setUserInfo } = props.userCtx;

  const [imports, setImports] = useState([]);

  const [collections, setCollections] = useState([]);

  const deleteImportByProductId = (pid) => {
    const newImports = imports.filter((i) => {
      return i._id !== pid;
    });
    setImports(newImports);
  };

  useEffect(() => {
    // httpCommon
    //   .get("/shops/" + userInfo.shopId + "/imports")
    //   .then((res) => {
    //     return res;
    //   })
    //   .then((res) => {
    //     const loadedImports = res.data;
    //     setImports(loadedImports);
    //     console.log("loaded imports");
    //     console.log(loadedImports);
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    setImports(ImportList);

    // httpCommon
    //   .get("/shopify/collections")
    //   .then((res) => {
    //     const collections = res.data;
    //     setCollections(collections);
    //     console.log("loaded collections");
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    setCollections(ShopCollections);
  }, []);

  return (
    <div
      className="container"
      style={{
        padding: "30px",
        //"background-color": "pink"
        maxWidth: "1112px"
      }}
    >
      {imports === []
        ? "loading..."
        : imports.map((element, index) => {
            return (
              <ImportItem
                userCtx={props.userCtx}
                deleteImportByProductId={deleteImportByProductId}
                key={element._id}
                index={index}
                importData={element}
                collections={collections}
              />
            );
          })}
    </div>
  );
};
