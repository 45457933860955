import React, { Fragment } from "react";

//child component
import { OrderItem } from "../order-item/order-item.component";

//bootstrap
import Nav from "react-bootstrap/Nav";

//api
//import httpCommon from "../../http-common";
import MyProducts from "../../data/my_products.json";
import MyOrders from "../../data/my_orders.json";

import { OrderCollectionContainer } from "./order-collection.styles";

import CheckoutModal from "../checkout-modal/checkout-modal.component";

import { SHOPIFY } from "../../utils/constants.js";

const tabKeyDefs = {
  ALL: "all",
  TO_ORDER: "to-order",
  FULFILLED: "fulfilled",
};

//compares a fulfillment status for a single order to the fulfillment status selected by the UI tab
const matchFulfillment = (ffStatus, selectedTabKey) => {
  //TODO: BETTER LOGIC FOR CHECKING FULFILLMENT STATUS
  switch (selectedTabKey) {
    case tabKeyDefs.ALL:
      return true;
    case tabKeyDefs.TO_ORDER:
      return (
        ffStatus === SHOPIFY.FULFILLMENT_STATUS.PARTIAL ||
        ffStatus === SHOPIFY.FULFILLMENT_STATUS.UNSHIPPED
      );
    case tabKeyDefs.FULFILLED:
      return ffStatus === SHOPIFY.FULFILLMENT_STATUS.FULFILLED;
    default:
      //this default should be unreachable, just in case
      throw new Error("invalid selected tabkey");
  }
};

// TODO:
// move Select all orders checkbox to the nav tab row?

export class OrderCollection extends React.Component {
  constructor() {
    super();
    this.state = {
      shopifyProductList: [],
      deletedProducts: [],
      orders: [],
      filteredOrders: [],
      filterText: "",
      selectedTabKey: tabKeyDefs.ALL,
      showCheckoutModal: false,
      checkoutOrderItem: null,
      checkoutJemmityItem: null,
    };
  }

  filterOrders = () => {
    //function for updating state.filteredOrders based on which order tab is selected and the search text
    this.setState(
      (state, props) => {
        const newFilteredOrders = state.orders.filter((order) => {
          //filter based on whether text matches and selected tab matches order fulfillment status
          const ffStatus = order.fulfillment_status;
          const textFilterResult = order.line_items.some((item) => {
            //if the search text is contained in at least one line item's name, return true
            return (
              item.name.toUpperCase().indexOf(state.filterText.toUpperCase()) >
              -1
            );
          });
          //check whether fulfillment status matches current tab
          const tabFilterResult = matchFulfillment(
            ffStatus,
            state.selectedTabKey
          );
          return textFilterResult && tabFilterResult;
        });
        return {
          filteredOrders: newFilteredOrders,
        };
      },
      () => {
        //console.log(this.state);
      }
    );
  };

  handleShowCheckoutModal = (order) => {
    //console.log("handleShowCheckoutModal");
    this.setState({ showCheckoutModal: true, checkoutOrderItem: order }, () => {
      //console.log(this.state);
    });
  };

  handleCloseCheckoutModal = () => {
    //console.log("handleCloseCheckoutModal");
    this.setState({ showCheckoutModal: false }, () => {
      //console.log(this.state);
    });
  };

  getVendorPrice = (line_item) => {
    if (line_item.jemmity_data.variants.length === 1) {
      return line_item.jemmity_data.variants[0].price;
    }

    let shopify_product_id = line_item.product_id;
    let shopify_variant_id = line_item.variant_id;

    let arrObj = this.state.shopifyProductList.find(
      (item) => item.shopify_product_id === shopify_product_id
    );

    try {
      let variant = arrObj.find(
        (item) => item.shopify_variant_id === shopify_variant_id
      );

      let jemmity_variant_id = variant.jemmity_variant_id;

      let order_variant = line_item.jemmity_data.variants.find(
        (item) => item.variant_id === jemmity_variant_id
      );

      return order_variant.price;
    } catch (err) {
      let sku = line_item.sku;
      let order_variant = line_item.jemmity_data.variants.find(
        (item) => item.sku === sku
      );
      return order_variant.price;
    }
  };

  componentDidMount() {
    //hard-coded userID for now
    //fetch shops object (and pull off order_list) from API
    const { userInfo, setUserInfo } = this.props.userCtx;

    // httpCommon
    //   .get(`/shops/${userInfo.shopId}/shopify_product_list`)
    //   .then((res) => {
    //     return res;
    //   })
    //   .then((res) => {

    //     this.setState(
    //       {
    //         shopifyProductList: res.data,
    //       },
    //       () => {
    //         console.log("loaded product list");
    //         console.log(this.state.shopifyProductList);
    //       }
    //     );
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    this.setState(
      {
        shopifyProductList: MyProducts,
      },
      () => {
        //console.log("loaded product list");
        //console.log(this.state.shopifyProductList);
      }
    );

    // httpCommon
    //   .get("/shops/" + userInfo.shopId + "/orders")
    //   .then((res) => {
    //     return res;
    //   })
    //   .then((res) => {
    //     const ordersList = res.data;

    //     ordersList.forEach((order) =>
    //       order.line_items.forEach(
    //         (line_item) =>
    //           (line_item.vendor_price = this.getVendorPrice(line_item))
    //       )
    //     );

    //     return this.setState(
    //       {
    //         orders: ordersList,
    //       },
    //       () => {
    //         console.log("loaded orders");
    //         console.log(this.state.orders);
    //         this.filterOrders();
    //       }
    //     );
    //   })
    //   .catch((err) => {
    //     console.error(err);
    //   });

    const ordersList = MyOrders;

    ordersList.forEach((order) =>
      order.line_items.forEach(
        (line_item) => (line_item.vendor_price = this.getVendorPrice(line_item))
      )
    );

    return this.setState(
      {
        orders: ordersList,
      },
      () => {
        //console.log("loaded orders");
        //console.log(this.state.orders);
        this.filterOrders();
      }
    );
  }

  render() {
    return (
      <Fragment>
        <CheckoutModal
          show={this.state.showCheckoutModal}
          handleClose={this.handleCloseCheckoutModal}
          order={this.state.checkoutOrderItem}
          //getVendorPrice={this.getVendorPrice}
        />
        <OrderCollectionContainer className="container">
          <div className="row">
            <div className="input-group">
              <span className="input-group-text bg-white border-end-0">
                <i className="fas fa-search" style={{ lineHeight: 1.5 }}></i>
              </span>
              <input
                type="text"
                className="form-control"
                placeholder="Filter product name"
                onChange={(event) =>
                  this.setState(
                    { filterText: event.target.value },
                    this.filterOrders
                  )
                }
              />
            </div>
          </div>

          <div className="row filter-header-row mt-3">
            <div className="col">
              <Nav
                defaultActiveKey={tabKeyDefs.ALL}
                onSelect={(selectedKey) => {
                  this.setState(
                    { selectedTabKey: selectedKey },
                    this.filterOrders
                  );
                }}
                variant="tabs"
              >
                <Nav.Item>
                  <Nav.Link eventKey={tabKeyDefs.ALL}>All Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabKeyDefs.TO_ORDER}>To Order</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={tabKeyDefs.FULFILLED}>Fulfilled</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </div>

          <div className="row select-all-header-row">
            <div className="col-2" style={{ marginTop: "10px" }}>
              <div>
                <input type="checkbox" />
                {""} Select all orders ({this.state.filteredOrders.length})
              </div>
            </div>

            <div className="col-2">
              <button className="btn d-flex  align-items-center justify-content-center btn-info btn-block">
                Export selected to CSV
              </button>
            </div>
          </div>

          <div className="row mt-3">
            <table className="table">
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th>Order #</th>
                  <th>Created</th>
                  <th>Customer</th>
                  <th>Payment Status</th>
                  <th>Customer Total</th>
                  <th>Your Total</th>
                  <th>Profit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  //if orders object is empty (hasn't loaded yet) just display loading text
                  this.state.filteredOrders !== [] ? (
                    this.state.filteredOrders.map((order) => {
                      return (
                        <OrderItem
                          order={order}
                          key={order.id}
                          handleShowCheckoutModal={this.handleShowCheckoutModal.bind(
                            this
                          )}
                          //getVendorPrice={this.getVendorPrice}
                        />
                      );
                    })
                  ) : (
                    <p>Loading</p>
                  )
                }
              </tbody>
            </table>
          </div>
        </OrderCollectionContainer>
      </Fragment>
    );
  }
}
