import React from "react";
import { UserContext } from "../../App";

import { OrderCollection } from "../../components/order-collection/order-collection.component";

export const Orders = () => {
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Orders</h4>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <UserContext.Consumer>
          {(value) => <OrderCollection userCtx={value} />}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
