import React from "react";

import { NotificationContainer } from "../../components/notification-container/notification-container.component";

export const NotificationsPage = () => {
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Notifications</h4>
          </div>
        </div>
      </div>
      <div className="mt-3 ms-3">
        <NotificationContainer />
      </div>
    </div>
  );
};
