export const PAYPAL_CLIENT_ID = {
  clientId:
    "ATn7yCYOm8a4G9wkccgbpChFcQfU-7KXZ5nlKABRUjw-zYOduCPaEYUnqJJLxsJ9I440EWP5IIanHXux",
};

export const SHOPIFY = {
  API_FULFILLMENT_STATUS: {
    ANY: "any",
    PARTIAL: "partial",
    SHIPPED: "shipped",
    UNSHIPPED: "unshipped",
    UNFULFILLED: "unfulfilled",
  },
  FULFILLMENT_STATUS: {
    FULFILLED: "fulfilled",
    PARTIAL: "partial",
    UNSHIPPED: null,
  },
};
