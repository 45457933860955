import Toast from "react-bootstrap/Toast";
import moment from "moment";

const getElapsedTime = (date) => {
  //placeholder function, logic could be much more elegant
  const seconds = Math.floor((Date.now() - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);

  let returnString = "";
  if (weeks > 0) {
    returnString += `${weeks} week`;
  } else if (days > 0) {
    returnString += `${days} day`;
  } else if (hours > 0) {
    returnString += `${hours} hour`;
  } else if (minutes > 0) {
    returnString += `${minutes} minute`;
  } else {
    returnString += `${seconds} second`;
  }
  if (returnString.slice(0, returnString.indexOf(" ")) !== "1")
    returnString += "s";

  return returnString + " ago";
};

export const NotificationToast = (props) => {
  const { toastCloseCallback, notification } = props;

  const createMarkup = () => {
    return { __html: notification.text };
  };

  return (
    <Toast
      key={notification.id}
      onClose={() => {
        toastCloseCallback(notification.id);
      }}
      //className="mb-3"
    >
      <Toast.Header>
        {/*<img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />*/}
        <strong className="me-auto">{notification.header}</strong>
        <small>{getElapsedTime(moment(notification.date))}</small>
      </Toast.Header>
      <Toast.Body>
        <div dangerouslySetInnerHTML={createMarkup()} />
        {/*Notification id:{"\n" + notification.id}*/}
      </Toast.Body>
    </Toast>
  );
};
