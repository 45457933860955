import React from "react";
import { NotificationToast } from "../notification-toast/notification-toast.component";
import { ToastContainer } from "react-bootstrap";
import SampleNotifications from "../../data/notifications.json";

export class NotificationContainer extends React.Component {
  constructor() {
    super();
    // this.state = {
    //   notifications: [
    //     {
    //       header: "you are in danger. beware",
    //       text: "abababa",
    //       date: new Date("September 30, 2021"),
    //       id: 1,
    //     },
    //     {
    //       header: "Price Change",
    //       text: "this is a notification",
    //       date: new Date("October 1, 2021"),
    //       id: 2,
    //     },
    //     {
    //       header: "Baby Yoda",
    //       text: "there is a baby yoda",
    //       date: new Date("October 4, 1984"),
    //       id: 3,
    //     },
    //   ],
    // };
    this.state = { notifications: SampleNotifications };

    this.removeNotification = (id) => {
      this.setState((state, props) => {
        const newNotifications = state.notifications.filter(
          (notification) => notification.id !== id
        );
        return { notifications: newNotifications };
      });
    };
  }

  render() {
    return (
      <ToastContainer>
        {this.state.notifications.map((notification) => {
          return (
            <NotificationToast
              notification={notification}
              toastCloseCallback={this.removeNotification}
            />
          );
        })}
      </ToastContainer>
    );
  }
}
