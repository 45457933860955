import styled from "styled-components";

export const CategoryWrapper = styled.span`
	.btn-default {
		color: #404040;
		background-color: #ffffff;
		border-color: #f2f2f2;
	}
	.btn-default:hover {
		background: linear-gradient(#e6e6e6);
	}

  .icon {
	  height: 30px;
  }

`;