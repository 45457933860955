import styled from "styled-components";

export const OrderItemContainer = styled.tr`
  padding: 10px;
  margin: 5px;
  margin-bottom: 20px;
  /* border-radius: 8px; */
  /* border-radius: 0px; */
  /* border-color: lightgrey;
  border-style: solid;
  border-width: 1px; */
  /* border-color: white; */
  background-color: white;

  text-align: left;

  .price {
    text-align: right;
  }
  td {
    padding-top: 5px;
    padding-right: 20px;
  }
  th {
    padding-right: 20px;
  }
  thead {
    border-style: none none solid none;
    border-color: lightgrey;
    border-width: 1px;
  }

  .order-table {
    margin-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-style: none none solid none;
    border-color: lightgrey;
    border-width: 1px;
  }

  .order-number {
    font-weight: bold;
  }

  .order-date {
    font-weight: bold;
  }

  .order-header {
    /* background-color: lightgrey;
            border-radius: 10px;
             */
    padding: 10px;
    /* background-color: #6ecdd4; */
  }
`;
