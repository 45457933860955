import styled, { css } from "styled-components";

// export const SideMenuItemContainer = styled.div`
//   border-bottom: 1px solid #e9e9e9;
//   padding: 10px 10px 10px 20px;
//   margin: 10px 10px 10px 0px;
//   cursor: pointer;
//   text-align: left;

//   ${(props) =>
//     props.selected &&
//     css`
//       border: unset;
//       color: white;
//       font-weight: bold;
//       background-image: linear-gradient(to right, navy, #0ab2b8);
//       border-bottom-right-radius: 7px;
//       border-top-right-radius: 7px;
//       box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
//         0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     `}

//   a {
//     color: black;
//     text-decoration: none;

//     ${(props) =>
//       props.selected &&
//       css`
//         color: white;
//         /* background-color: #0ab2b8; */
//         font-weight: bold;
//       `}
//   }

//   &:hover {
//     background-color: #e9e9e9;
//     color: navy;
//     border: 1px solid navy;
//     border-left: none;
//     border-bottom-right-radius: 7px;
//     border-top-right-radius: 7px;
//     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
//     font-weight: bold;

//     ${(props) =>
//       props.selected &&
//       css`
//         border: unset;
//         color: white;
//         /* background-color: #0ab2b8; */
//         font-weight: bold;
//         background-image: linear-gradient(to right, navy, #0ab2b8);
//         border-bottom-right-radius: 7px;
//         border-top-right-radius: 7px;
//         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
//           0 6px 20px 0 rgba(0, 0, 0, 0.19);
//       `}

//     a {
//       color: navy;

//       ${(props) =>
//         props.selected &&
//         css`
//           color: white;
//           /* background-color: #0ab2b8; */
//           font-weight: bold;
//         `}
//     }
//   }
// `;


export const SideMenuItemContainer = styled.li`
  border-bottom: 1px solid #e9e9e9;
  padding: 10px 10px 10px 20px;
  margin: 10px 10px 10px 0px;
  cursor: pointer;
  text-align: left;
  color: black;

  ${(props) =>
    props.selected &&
    css`
      border: unset;
      color: white;
      font-weight: bold;
      background-image: linear-gradient(to right, navy, #0ab2b8);
      border-bottom-right-radius: 7px;
      border-top-right-radius: 7px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    `}

  a {
    color: black;
    text-decoration: none;

    ${(props) =>
      props.selected &&
      css`
        color: white;
        /* background-color: #0ab2b8; */
        font-weight: bold;
      `}
  }

  &:hover {
    background-color: #e9e9e9;
    color: navy;
    border: 1px solid navy;
    border-left: none;
    border-bottom-right-radius: 7px;
    border-top-right-radius: 7px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-weight: bold;

    ${(props) =>
      props.selected &&
      css`
        border: unset;
        color: white;
        /* background-color: #0ab2b8; */
        font-weight: bold;
        background-image: linear-gradient(to right, navy, #0ab2b8);
        border-bottom-right-radius: 7px;
        border-top-right-radius: 7px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
      `}

    a {
      color: navy;

      ${(props) =>
        props.selected &&
        css`
          color: white;
          /* background-color: #0ab2b8; */
          font-weight: bold;
        `}
    }
  }
`;