import React from "react";

//package components
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//description pane: TabPane that displays description (body_html) info for import-item
//allows it to be edited in a rich text editor

export const DescriptionPane = ({ description, updateImportState }) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={description}
        onChange={(event, editor) => {
          //when editor contents is changed, immediately update description state
          // console.log(editor.getData())
          updateImportState({
            body_html: editor.getData(),
          });
        }}
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          // console.log("Editor is ready to use!", editor);
          editor.editing.view.change((writer) => {
            writer.setStyle(
              "height",
              "180px",
              editor.editing.view.document.getRoot()
            );
          });
        }}
      />
    </div>
  );
};
