import React from "react";

import { SideMenuItemContainer } from "./side-menu-item.styles";

const SideMenuItem = ({ text, url, iconClass, selected, iconsOnly = false }) => {
  // return (
  //   <SideMenuItemContainer
  //     selected={url === window.location.pathname}
  //     onClick={() => (window.location.href = url)}
  //   >
  //     <i className={iconClass} style={{ marginRight: 5 }}></i>
  //     <span>{text}</span>
  //   </SideMenuItemContainer>
  // );

  if (iconsOnly) {
    return (
      <SideMenuItemContainer
        selected={url === window.location.pathname}
        onClick={() => (window.location.href = url)}
      >
        <i className={iconClass} style={{ marginRight: 5, fontSize: "1.5rem" }}></i>
      </SideMenuItemContainer>
    );
  } else
    return (
      <SideMenuItemContainer
        selected={url === window.location.pathname}
        onClick={() => (window.location.href = url)}
      >
        <i className={iconClass} style={{ marginRight: 5 }}></i>
        <span>{text}</span>
      </SideMenuItemContainer>
    );
};

export default SideMenuItem;
