import styled from "styled-components";

export const ProductCardContainer = styled.div`
  width: 18rem;
  transition: all .2s ease-in-out; 

  .card-title {
    text-align: left;
    font-weight: bold;
  }

  .card-text {
    text-align: left;
  }

  .card-footer {
    border-top: unset;
    background-color: unset;
  }

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    transform: scale(1.02);
  }
`;
