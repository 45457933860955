import "./App.css";
import { useState, createContext } from "react";
//import axios from "axios";

//Pages
import Dashboard from "./pages/dashboard/dashboard.component";
//import Login from "./pages/login/login.component";

export const UserContext = createContext();

function App() {
  //const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState({
    loggedIn: true,
    shop_name: "The Greatest Shop in the World",
    shopId: "",
    importIds: [],
    // collections: []
  });

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo }}>
      <Dashboard />
    </UserContext.Provider>
  );
}

export default App;
