//placeholder

export const ImagesPane = () => {
  return (
    <div>
      <div className="row mb-3 me-1">
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-2 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/iP7tRhp.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-3 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/BsnZ6dv.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-2 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/iP7tRhp.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
        <div className="col-3 d-flex justify-content-evenly">
          <div className="ps-3 pe-2">
            <input
              className="form-check-input"
              type="checkbox"
              id="checkboxNoLabel"
              value=""
              aria-label="..."
            />
          </div>
          <img
            src="https://i.imgur.com/BsnZ6dv.png"
            alt="Placeholder"
            style={{ width: "90%" }}
          />
        </div>
      </div>
    </div>
  );
};
