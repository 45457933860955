import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id":
    "ATn7yCYOm8a4G9wkccgbpChFcQfU-7KXZ5nlKABRUjw-zYOduCPaEYUnqJJLxsJ9I440EWP5IIanHXux",
  currency: "CAD",
  intent: "capture",
};

const buttonStyle = {
  layout: "vertical",
  color: "gold",
  shape: "rect",
  label: "paypal",
};

const PayPalButton2 = ({
  orderTotal,
  description,
  lineItems,
  subTotal,
  tax,
  shippingCost,
}) => {
  const createOrder = (data, actions) => {
    // console.log("lineItems");
    // console.log(lineItems);

    const items = lineItems.map((item) => ({
      name: item.name,
      description: item.name,
      unit_amount: {
        currency_code: "CAD",
        value: item.price,
      },
      tax: {
        currency_code: "CAD",
        value: item.tax_lines.length > 0 ? item.tax_lines[0].price : 0,
      },
      quantity: item.quantity,
      sku: item.sku,
    }));

    // console.log("createOrder items");
    // console.log(items);

    return actions.order.create({
      purchase_units: [
        {
          description: description,
          amount: {
            currency_code: "CAD",
            value: orderTotal,
            breakdown: {
              item_total: { currency_code: "CAD", value: subTotal },
              shipping: { currency_code: "CAD", value: shippingCost },
              tax_total: { currency_code: "CAD", value: tax },
            },
          },
          items: items,
        },
      ],
    });
  };

  const captureOrder = async (data, actions) => {
    const order = await actions.order.capture().then((details) => {
      // console.log("captureOrder details");
      // console.log(details);
      const name = details.payer.name.given_name;
      //console.log(name);

      // save into DB

      // redirect to success/receipt page
    });

    // console.log("paypal button onApprove");
    // console.log(order);

    return order;
  };

  return (
    <PayPalScriptProvider
    //options={initialOptions}
    >
      <PayPalButtons
        style={buttonStyle}
        forceReRender={[
          orderTotal,
          description,
          lineItems,
          subTotal,
          tax,
          shippingCost,
        ]}
        fundingSource="paypal"
        // createOrder={(data, actions) => createOrder(data, actions)}
        // onApprove={(data, actions) => captureOrder(data, actions)}
        // onError={(err) => {
        //   console.log("Error occurred");
        //   console.log(err);
        // }}
        onClick={(e) => e.preventDefault()}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton2;
