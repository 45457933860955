import React, { useState, useEffect } from "react";

import { Modal, Button } from "react-bootstrap";

import PayPalButton from "../paypal-button/paypal-button.component";
import PayPalButton2 from "../paypal-button/paypal-button2.component";

import { CheckoutModalBodyContainer } from "./checkout-modal.styles";

const CheckoutModal = ({ show, handleClose, order }) => {
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [shippingPrice, setshippingPrice] = useState(0);
  const [tax, setTax] = useState(0);

  // const [paymentSucceeded, setPaymentSucceeded] = useState(false);
  // const [paypalErrorMessage, setPaypalErrorMessage] = useState("");
  // const [paypalOrderID, setPaypalOrderID] = useState(false);
  // const [billingDetails, setBillingDetails] = useState("");
  // const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  useEffect(() => {
    if (order !== null) {
      let tempSubTotal = 0;
      order.line_items.forEach(
        (item) => (tempSubTotal += item.vendor_price * item.quantity)
      );
      setSubTotal(tempSubTotal);

      setshippingPrice(12.34);

      setTax(order.total_tax);

      setTotal(subTotal + shippingPrice);
    }

    // dispatch({
    //   type: "resetOptions",
    //   value: {
    //     ...options,
    //     currency: currency,
    //   },
    // });
  }, [order, subTotal, shippingPrice, total, tax]);

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          Checkout{" "}
          <span className="badge bg-info text-black ms-2">
            Order #
            {order !== null && order !== undefined ? order.order_number : null}
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {order !== null && order !== undefined ? (
          <CheckoutModalBodyContainer className="row order-table m-2">
            <table className="table">
              <thead>
                <tr>
                  <th>Images</th>
                  <th>Product</th>
                  <th>Supplier</th>
                  <th>Quantity</th>
                  <th className="price">Customer Cost</th>
                  {/* <th>Status</th> */}
                  <th className="price cost-col">Your Cost</th>
                </tr>
              </thead>
              <tbody>
                {order.line_items.map((item) => {
                  return item.product_id !== null ? (
                    <tr key={item.id}>
                      <td>
                        <img src="https://via.placeholder.com/50" fluid />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.vendor || "N/A"}</td>
                      <td>{item.quantity}</td>
                      {/* <button className="btn d-flex  align-items-center justify-content-center btn-primary btn-block">
                                  Check Out
                                  </button> */}
                      <td className="price">
                        ${Number(item.price).toFixed(2)} CAD
                      </td>
                      <td className="price cost-col">
                        ${Number(item.vendor_price).toFixed(2)} CAD
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td className="alert alert-warning pt-2 pb-2" colSpan={6}>
                        Product deleted
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-end">
              <table>
                <tbody>
                  <tr className="fw-bold">
                    <td className="text-end">Sub-total:</td>
                    <td className="text-end">
                      ${Number(subTotal).toFixed(2)} CAD
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end border-top">Shipping country:</td>
                    <td className="text-end border-top">
                      {order.shipping_address
                        ? order.shipping_address.country
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end">Shipping method:</td>
                    <td>
                      <select
                        className="form-select form-select-sm d-flex"
                        style={{ width: "120px" }}
                      >
                        <option value={1} selected>
                          Standard
                        </option>
                        <option value={2}>Express</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end">Shipping price:</td>
                    <td className="text-end">
                      ${Number(shippingPrice).toFixed(2)} CAD
                    </td>
                  </tr>
                  <tr>
                    <td className="text-end">Tax:</td>
                    <td className="text-end">${Number(tax).toFixed(2)} CAD</td>
                  </tr>
                  <tr className="fw-bold">
                    <td className="text-end border-top">Total price:</td>
                    <td
                      className="text-end border-top"
                      style={{ color: "orangered" }}
                    >
                      ${Number(total).toFixed(2)} CAD
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CheckoutModalBodyContainer>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <div>&nbsp;</div>
          <div>
            {/*<button
              className="btn btn-success font-weight-bold"
              style={{ backgroundColor: "#6BC300", borderColor: "#6BC300" }}
            >
              Shopify Payment
            </button>*/}
            {/*<button className="btn btn-warning text-primary font-weight-bold ml-2">
              PayPal
          </button>*/}
            {/*<PayPalButtons
              style={paypalStyle}
              forceReRender={[total, currency, paypalStyle]}
              createOrder={createPaypalOrder}
              onApprove={onPaypalApprove}
              onError={onPaypalError}
            />*/}
            {/*<PayPalButton
              orderTotal={total}
              description={`Jemmity Order ${
                order !== null && order !== undefined ? order.order_number : ""
              }`}
              lineItems={
                order !== null && order !== undefined ? order.line_items : null
              }
              subTotal={subTotal}
              tax={tax}
              shippingCost={shippingPrice}
            />*/}
            <PayPalButton2
              orderTotal={total}
              description={`Jemmity Order ${
                order !== null && order !== undefined ? order.order_number : ""
              }`}
              lineItems={
                order !== null && order !== undefined ? order.line_items : null
              }
              subTotal={subTotal}
              tax={tax}
              shippingCost={shippingPrice}
              onClick={(e) => e.preventDefault()}
            />
          </div>
          <div>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default CheckoutModal;
