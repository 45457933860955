import { UserContext } from "../../App";
import { ImportCollection } from "../../components/import-collection/import-collection.component";

export const Imports = () => {
  //TODO
  //PAGINATION
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Import List</h4>
          </div>
        </div>
      </div>
      <div>
        <UserContext.Consumer>
          {(value) => <ImportCollection userCtx={value} />}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
