import React, { useState, useCallback } from "react";

import { UserContext } from "../../App";

import ProductCollection from "../../components/product-collection/product-collection.component";

export const MyProductsPage = () => {
  const [productsFiltered, setProductsFiltered] = useState(false);
  const [rebindAllProducts, setRebindAllProducts] = useState(false);

  const updateProductsFiltered = useCallback(
    (val) => {
      setProductsFiltered(val);
    },
    [setProductsFiltered]
  );

  const updateRebindAllProducts = useCallback(
    (val) => {
      setRebindAllProducts(val);
    },
    [setRebindAllProducts]
  );

  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">My Products</h4>
          </div>
        </div>
      </div>
      <div>
        <UserContext.Consumer>
          {(value) => (
            <ProductCollection
              productsUrl={"/shopify/products/"}
              userCtx={value}
              productsFiltered={productsFiltered}
              updateProductsFiltered={updateProductsFiltered}
              rebindAllProducts={rebindAllProducts}
              updateRebindAllProducts={updateRebindAllProducts}
            />
          )}
        </UserContext.Consumer>
      </div>
    </div>
  );
};
