import React, { useEffect, useState, Fragment } from "react";
//import httpCommon from "../../http-common";
import ProductCard from "../product-card/product-card.component";
import ProductCardModal from "../product-card-modal/product-card-modal.component";

import SampleProducts from "../../data/sample_products.json";
import MyProducts from "../../data/my_products.json";

const ProductCollection = ({
  productsUrl,
  userCtx,
  categoryFilter = "",
  subcategoryFilter = "",
  productsFiltered = null,
  updateProductsFiltered = null,
  rebindAllProducts = null,
  updateRebindAllProducts = null,
}) => {
  // const { productsUrl } = props;
  // const { userCtx } = props;
  const { importIds } = userCtx.userInfo;
  const [products, setProducts] = useState([]);
  //const [displayedProducts, setDisplayedProducts] = useState([]);
  //const numberItemsPerLoad = 20;
  const [pageNumber, setPageNumber] = useState(0);
  const [productsLoaded, setProductsLoaded] = useState(false);
  const [allProductsLoaded, setAllProductsLoaded] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  //const [productsFiltered, setProductsFiltered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalProduct, setModalProduct] = useState(null);

  useEffect(() => {
    // console.log("product-collection useEffect");
    // console.log(
    //   "category = " +
    //     categoryFilter +
    //     ", subcategoryFilter = " +
    //     subcategoryFilter
    // );

    if (productsLoaded === false || rebindAllProducts === true) {
      //console.log("loading products...");

      if (pageNumber === 0) {
        let results;
        if (productsUrl === "/products/") {
          results = SampleProducts;
        } else {
          results = MyProducts;
        }

        //console.log(results);

        setProducts(results);
        setProductsLoaded(true);
        updateRebindAllProducts(false);
        updateProductsFiltered(false);

        // httpCommon
        //   .get(productsUrl)
        //   .then((res) => {
        //     return res;
        //   })
        //   .then((res) => {
        //     setProducts(res.data.products);
        //     setProductsLoaded(true);

        //     updateRebindAllProducts(false);
        //     updateProductsFiltered(false);
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //   });
      } else {
        // httpCommon
        //   .get(productsUrl + "?page=" + pageNumber)
        //   .then((res) => {
        //     return res;
        //   })
        //   .then((res) => {
        //     console.log("loadMoreProducts results");
        //     console.log(res.data.products);
        //     if (res.data.products.length > 0) {
        //       setProducts(products.concat(res.data.products));
        //     } else {
        //       setAllProductsLoaded(true);
        //     }
        //     setProductsLoaded(true);
        //   })
        //   .catch((err) => {
        //     console.error(err);
        //   });
      }
    }

    if (productsLoaded && productsFiltered === false) {
      // console.log("filtering products...");
      // console.log(products);

      if (subcategoryFilter !== "") {
        //console.log("trying to filter on subcategory...");

        const results = products.filter((product) => {
          if (product.hasOwnProperty("categories")) {
            return product.categories.find((category) => {
              return category.subcategories.includes(subcategoryFilter);
            });
          }
        });

        // console.log("subcategoryFilter products");
        // console.log(results);

        setFilteredProducts(results);
      } else if (categoryFilter !== "") {
        //console.log("trying to filter on category...");

        const results = products.filter((product) => {
          if (product.hasOwnProperty("categories")) {
            return product.categories.find((category) => {
              return category.name === categoryFilter;
            });
          }
        });

        // console.log("categoryFilter products");
        // console.log(results);

        setFilteredProducts(results);
      } else {
        //console.log("no filters...");
        setFilteredProducts(products);
      }

      updateProductsFiltered(true);
    }
  }, [
    productsUrl,
    pageNumber,
    productsLoaded,
    allProductsLoaded,
    categoryFilter,
    subcategoryFilter,
    productsFiltered,
    rebindAllProducts,
  ]);

  const loadMoreProducts = () => {
    setPageNumber(pageNumber + 1);
    setProductsLoaded(false);
  };

  const handleCloseModal = () => {
    setModalProduct(null);
    setShowModal(false);
  };

  const handleShowModal = (product) => {
    // console.log("product-collection handleShowModal");
    // console.log(product);

    // const isImported = importIds.some((x) => {
    //   return x === product._id;
    // });
    if (productsUrl === "/products/") product.isMyProduct = false;
    else product.isMyProduct = true;

    setModalProduct(product);
    setShowModal(true);
  };

  return (
    <Fragment>
      <div className="container" style={{ padding: "10px" }}>
        <div
          className="d-flex align-content-center flex-wrap"
          style={{ marginBottom: "20px" }}
        >
          {filteredProducts.map((product, idx) => {
            const isImported = importIds.some((x) => {
              return x === product._id;
            });
            return (
              <div style={{ padding: "10px" }}>
                <ProductCard
                  isImported={isImported}
                  userCtx={userCtx}
                  {...product}
                  key={product._id}
                  isShopifyProduct={productsUrl === "/shopify/products/"}
                  handleShowModal={handleShowModal}
                  product={product}
                />
              </div>
            );
          })}
        </div>
        <div
          className="d-flex justify-content-center"
          style={{
            marginBottom: "20px",
          }}
        >
          <button
            type="button"
            className="btn btn-info"
            onClick={(event) => {
              event.preventDefault();
              loadMoreProducts();
            }}
            style={{
              marginBottom: "20px",
              display: allProductsLoaded ? "none" : "inherit",
            }}
          >
            Load more
          </button>
        </div>
      </div>
      {showModal ? (
        <ProductCardModal
          show={showModal}
          handleClose={handleCloseModal}
          product={modalProduct}
        />
      ) : null}
    </Fragment>
  );
};

export default ProductCollection;
