//variant pane: TabPane that displays variant info for import-item

//takes state object and its setState function for variant info
export const VariantPane = ({ variants, updateImportState }) => {
  //function for handling change in one of the form fields
  const handleChange = (e, index, fieldName) => {
    //do not alter old state, create new temp variables
    let newVariants = [...variants];

    //handle checkboxes separately since they use target.checked not target.value
    newVariants[index][fieldName] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    updateImportState({
      variants: newVariants,
    });
  };

  return (
    //TODO: optimize array state change function? not a problem yet.
    <table className="table">
      <thead style={{ borderBottom: "1px solid black" }}>
        <tr>
          <th>
            <input
              type="checkbox"
              //checkbox in the header row of the table: checking it should change the checked value of all other checkboxes to its value
              //check if every variant is selected
              // defaultChecked = {variants.every((val) => val.selected)}
              checked={variants.every((val) => val.selected)}
              //on change loop through variants' state and change to checked
              onChange={(e) => {
                let newVariants = [...variants];
                for (let i = 0; i < variants.length; i++) {
                  let newVariant = { ...newVariants[i] };
                  newVariant.selected = e.target.checked;
                  newVariants[i] = newVariant;
                }
                //console.log(newVariants);
                updateImportState({
                  variants: newVariants,
                });
              }}
            />
          </th>
          <th>Images</th>
          <th>SKU</th>
          <th>Variant Name</th>
          <th>Stock</th>
          <th>Weight</th>
          <th className="price">Cost</th>
          <th className="price">Store Price</th>
          <th className="price">Profit</th>
        </tr>
      </thead>
      <tbody>
        {variants.map((variant, index) => {
          return (
            <tr key={variant.id}>
              <td>
                <input
                  type="checkbox"
                  // defaultChecked = {variant.selected}
                  checked={variant.selected}
                  onChange={(e) => {
                    handleChange(e, index, "selected");
                  }}
                />
              </td>
              <td>
                <img src="https://via.placeholder.com/50" />
              </td>
              <td>
                <input
                  className="form-control"
                  defaultValue={variant.sku}
                  onChange={(e) => {
                    handleChange(e, index, "sku");
                  }}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  defaultValue={variant.title}
                  onChange={(e) => {
                    handleChange(e, index, "title");
                  }}
                />
              </td>
              <td>{variant.inventory_quantity}</td>
              <td>
                {variant.weight} {variant.weight_unit}
              </td>
              <td className="price">
                ${parseFloat(variant.jemmity_price.price.amount).toFixed(2)} CAD
              </td>
              <td className="price" style={{ width: "200px" }}>
                <div class="input-group">
                  <span class="input-group-text">$</span>
                  <input
                    className="form-control"
                    defaultValue={variant.price}
                    onChange={(e) => {
                      handleChange(e, index, "price");
                    }}
                  />
                  <span class="input-group-text">CAD</span>
                </div>
              </td>
              <td className="price">
                {" "}
                $
                {parseFloat(
                  variant.price - variant.jemmity_price.price.amount
                ).toFixed(2)}{" "}
                CAD
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
