import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { Popover } from "react-bootstrap";

import { OrderItemContainer } from "./order-item.styles";

export const OrderItem = (props) => {
  const { order, handleShowCheckoutModal } = props;
  const { line_items, customer } = order;
  const checkOutVisible = false;

  const [subTotal, setSubTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [shippingPrice, setshippingPrice] = useState(0);
  const [total, setTotal] = useState(0);

  const [profit, setProfit] = useState(0);

  useEffect(() => {
    if (order !== null) {
      let tempSubTotal = 0;
      order.line_items.forEach(
        (item) => (tempSubTotal += item.vendor_price * item.quantity)
      );
      setSubTotal(tempSubTotal);

      setshippingPrice(12.34);

      setTax(order.total_tax);

      setTotal(subTotal + shippingPrice);

      setProfit(order.total_price_set.shop_money.amount - total);
    }

    // dispatch({
    //   type: "resetOptions",
    //   value: {
    //     ...options,
    //     currency: currency,
    //   },
    // });
  }, [order, subTotal, shippingPrice, total, tax]);

  return (
    <OrderItemContainer className="">
      <td>
        <input type="checkbox" />
      </td>
      <td>
        <span className="badge bg-info text-black" style={{ fontSize: "15px" }}>
          {order.order_number}
        </span>
      </td>
      <td>{order.created_at.substr(0, 10)}</td>
      <td>
        {customer
          ? customer.first_name + " " + customer.last_name
          : "Customer info not found"}
      </td>
      <td>{order.financial_status === "paid" ? "Paid" : "Pending"}</td>
      <td>
        ${order.total_price_set.shop_money.amount}{" "}
        {order.total_price_set.shop_money.currency_code}
      </td>
      <td style={{ color: "orangered", fontWeight: "700" }}>${total} CAD</td>
      <td>${Number(profit).toFixed(2)} CAD</td>
      <td>
        <button
          className="btn btn-primary btn-sm"
          disabled={checkOutVisible}
          onClick={(event) => {
            event.preventDefault();
            handleShowCheckoutModal(order);
          }}
          type="button"
        >
          Check Out
        </button>
      </td>
      {/*<div className="row order-header m-0">
        <div className="col-1">
          <input type="checkbox" />
        </div>
        <div className="col-2 order-number">Order #{order.order_number}</div>
        <div className="col-2 order-date">
          Date: {order.created_at.substr(0, 10)}
        </div>
        <div className="col-3 fw-bold">
          Customer Payment Status:{" "}
          {order.financial_status === "paid" ? "Paid" : "Pending"}
        </div>
        <div className="col-4">
          <div className="float-end">
            <button
              disabled={checkOutVisible}
              className="btn btn-primary me-2"
              onClick={(event) => {
                event.preventDefault();
                handleShowCheckoutModal(order);
              }}
              type="button"
            >
              Check Out
            </button>

            <OverlayTrigger
              trigger="click"
              placement="left"
              overlay={
                <Popover id="popover-positioned-left" placement="left">
                  <Popover.Header as="h3">Customer Info</Popover.Header>
                  <Popover.Body>
                    {customer
                      ? customer.first_name + " " + customer.last_name
                      : "Customer info not found"}
                  </Popover.Body>
                </Popover>
              }
            >
              <button className="btn btn-success">
                Customer Info
              </button>
            </OverlayTrigger>
          </div>
        </div>
      </div>
      <div className="row order-table m-2">
        <table>
          <thead>
            <tr>
              <th>Images</th>
              <th>Product</th>
              <th>Quantity</th>
              <th className="price">Cost</th>
              <th className="price">Store Price</th>
              <th>Supplier</th>
            </tr>
          </thead>
          <tbody>
            {line_items.map((item) => {
              return item.product_id !== null ? (
                <tr key={item.id}>
                  <td>
                    <img src="https://via.placeholder.com/50" fluid />
                  </td>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td className="price">
                    ${Number(item.vendor_price).toFixed(2)}
                  </td>
                  <td className="price">${Number(item.price).toFixed(2)}</td>
                  <td>{item.vendor || "N/A"}</td>
                </tr>
              ) : (
                <tr>
                  <td className="alert alert-warning pt-2 pb-2" colSpan={6}>
                    Product deleted
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
          <div className="row btnfooter"></div>*/}
    </OrderItemContainer>
  );
};
