
import styled from "styled-components";

export const OrderCollectionContainer = styled.div`
    text-align:left;
    padding: 10px;

    .filter-header-row {
        border-style: none none solid none;
        border-color: lightgrey;
        border-width: 1px;
        padding-bottom: 2px;
        padding-left: 38px;
        padding-top: 5px;

        .d-flex {
            
        }

        .nav {
            border-style: none;
        }
    }

    .select-all-header-row {
        border-style: none none solid none;
        border-color: lightgrey;
        border-width: 1px;
        padding-bottom: 10px;
        padding-left: 38px;
        padding-top: 10px;
        .btn {
            margin-top: 5px;
        }
    }

`;