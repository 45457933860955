import React from "react";

export const HelpCentrePage = () => {
  return (
    <div>
      <div
        className="sticky-md-top"
        style={{
          backgroundColor: "#f3f3f3",
          boxShadow: "0 .5rem 1rem -.5rem rgba(0,0,0,.15)",
        }}
      >
        <div className="row">
          <div className="col-12 mt-3 mb-1 ms-3">
            <h4 className="text-uppercase">Help Centre</h4>
          </div>
        </div>
      </div>
    </div>
  );
};
